.badge-lg {
    font-size: 20px;
    padding: 10px;
}

.letter-wide-badge {
    border-radius: 50%;
    padding: 12px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 1.4rem !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

// Better way of doing a round badge
.round-badge {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    height: 32px;
    width: 32px;

    border-radius: 100%;

    overflow-x: hidden;
}
