h1, .h1{
color:@color-primary;
font-size:24px;
font-weight:normal;
line-height:24px;
font-family: "MyriadPro";
}

h2, .h2{
color:#000000;
font-size:20px;
font-weight:normal;
font-family: "MyriadPro";
}

h3, .h3{
color:#333333;
font-weight:normal;
font-size:18px;
}

h4, .h4{
color:@color-secondary;
font-weight:normal;
font-size:16px;
}

h1:first-letter,
.h1:first-letter,
h2:first-letter,
.h2:first-letter,
h3:first-letter,
.h3:first-letter,
h4:first-letter,
.h4:first-letter{
 text-transform:uppercase;
}

a{
color:#000000;
}

a:hover{
color:@color-primary;
}

p{

}

img{
border:none;
}

hr {
background-color:#DDDDDD;
border:0 none;
color:#DDDDDD;
height:1px;
}

ul{
margin:0px;
font-size:12px;
}

li{
margin:0px;
font-size:12px;
}

.text-primary{
  color:@color-primary;
}

.text-secondary{
  color:@color-secondary;
}

.text-tertiary{
  color:@color-tertiary;
}

.text-success{
  color:@brand-success;
}

.text-danger{
  color:@brand-danger;
}

.text-left{
text-align: left;
}

.text-center{
text-align: center;
}

.text-right{
text-align: right;
}

.libelle {
color:#222222;
font-weight:bold;
}

.pagination{
color:#222222;
font-size:12px;
display:block;
clear: both;
}

.img_left{
float:left;
padding:0px 10px 10px 0px;
}

.img_icone_left{
float:left;
padding:0px 10px 0px 0px;
}

.img_right{
float:right;
padding:0px 0px 10px 10px;
}

.div_left{
width:50%;
background-color: #eeeeee;
float:left;
margin:0px 10px 10px 0px;
}

.document {
padding:5px 5px 5px 5px;
}

.activity-date {
  color: #666666;
  font-size: 12px;
}

.telephone{
  font-size:18px;
  font-family:'SourceSansProLight'
}

div.row{
  color:#000000 !important;
}


.fs-24{
  font-size: 24px !important;
}
