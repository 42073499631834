/*------------------ARTICLE-------------------*/

#article{
float:left;
width:100%;
}

#article h1{
color:#000000;
font-weight:bold;
font-size:36px;
margin:5px 0px;
padding:0px;
}

#article p{
margin:10px 0px;
text-align:justify;
}

#article .contenu{
float:left;
padding:0px 20px;
}

#article .chapeau{
float:left;
width:100%;
}

#article .chapeau p{
font-size:14px;
font-weight:bold;
}

#article .zone1{
float:left;
width:100%;
margin:10px 0px;
}

#article .zone1 img{
float:left;
display:block;
background:#000000;
margin:10px 10px 10px 0px;
width:400px;
height:280px;
}

#article .zone2{
float:left;
width:100%;
margin:10px 0px;
}

#article .zone2_g{
float:left;
margin:10px 10px 10px 0px;
width:200px;
}

#article .zone2_d{
float:right;
margin:10px 0px 10px 10px;
width:200px;
}

#article .zone2 img{
display:block;
background:#000000;
margin-bottom:10px;
width:200px;
height:140px;
}

#article .zone2 .extrait{
float:left;
padding:10px;
background:#eeeeee;
font-size: 12px;
}

#article .zone3{
float:left;
width:100%;
background:#eeeeee;
margin:10px 0px;
}

#article .zone3 .contenu{
float:left;
background:#eeeeee;
padding:10px;
}

#article .zone4{
float:left;
width:100%;
margin:10px 0px;
}

#article .zone4 p{
border-bottom:solid 1px #dddddd;
padding:5px 0px;
}

#article .zone4 p a{
text-decoration:none;
}

.document h1{
margin:0px 0px 5px 0px;
}

.document_gauche{
float:left;
width:67%;
padding-right:3%;
}

.document_colonne_droite{
float:left;
width:29%;
border-left:solid 1px #E2E2E2;
}

.document_bas{
border-top:solid 1px #E2E2E2;
padding:5px 5px 5px 5px;
clear:both;
}

.document_item{
clear:both;
float:left;
padding:10px 0px 0px 5px;
}

.document_item p{
text-align:justify;
}

.document_item h2{
color:#01415e;
font-weight:bold;
font-size:12px;
padding:0px;
display:inline;
}

.document_item a{
font-size:10px;
}

a.lien_abstract{
font-size:11px;
}
