.table-fixed-head{
  border:2px solid red;
}

.table-fixed-head .rotate {
  writing-mode:vertical-lr;
  text-orientation: mixed;
  transform: rotate(-180deg);
}

.table-fixed-head .column-label-150{
  width:150px;
  display:inline-block;
}

.table-fixed-head .column-label{
  width:400px;
  display:inline-block;
}

.table-fixed-head .column-data{
  width:50px !important;
  overflow: hidden !important;
  display:inline-block;
}

.table-fixed-head thead, .table-fixed-head thead tr{
display: block;
width:100%;
}

.table-fixed-head-wide-height tbody .column-data, .table-fixed-head-wide-height tbody .column-label{
  height:100px;
}

.table-fixed-head tbody{
  display:block;
  height:30em;
  overflow-y:scroll;
}

.table tr.titre_table_espace td {
    background: #a4dfff;
    color: #000000;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
}

.table td.item {
    border: 0px;
    color: #62b4e1;
    font-size: 12px;
    font-weight: bold;
}

.table td.item .texte {
    color: #000000;
    font-weight: normal;
}

.table tr.no_border td {
    border: 0px;
}

.filtre {
    background: #fcfcfc;
    border: solid 1px #e3e3e3;
    padding: 10px 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
}

.filtre tr td {
    padding: 10px;
}

.filtre .libelle {
    color: #000000;
}

.filtre label {
    font-weight: bold;
    font-size: 12px;
}


/*------------------ Table formulaire --------------------*/

.table_form {
    margin: 0px;
}

.table_form tr {}

.table_form td {
    line-height: 20px;
    padding: 2px 5px;
    font-size: 12px;
}

.table_form th {
    color: #0c7e04;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    line-height: 20px;
    padding: 5px 5px;
}

.table_infos {
    border: solid 1px #DEDEDE;
    margin: 0px;
    border-collapse: collapse;
    font-size: 12px;
}

.table_infos td,
.table_infos th {
    padding: 5px 5px;
    font-size: 11px;
    height: 30px;
}

table.table_infos tr:nth-child(even) {
    background: #f7f7f7;
}

table.table_infos tr:nth-child(odd) {
    background: #FFFFFF;
}

table.table_infos tr:nth-child(even):hover,
table.table_infos tr:nth-child(odd):hover {
    background: #cbe5f0;
}

.table_formulaire {
    border: solid 1px #DEDEDE;
    margin: 0px;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}

.table_formulaire tr {}

.table_formulaire td,
.table_formulaire th {
    padding: 5px 5px;
    font-size: 11px;
    height: 30px;
}

.table_formulaire td.entete_table,
.table_formulaire tr.entete_table td {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    line-height: 20px;
    padding: 5px 5px;
}


/*---------------------------TABLEAU TRIABLE---------------------------*/

.entete_col {
    color: #000000;
}

.entete_col_triAsc,
.entete_col_triDesc {
    text-decoration: underline;
}

.entete_col .triActions,
.entete_col_triAsc .triActions,
.entete_col_triDesc .triActions,
.triActions {
    float: left;
    text-align: left;
    margin-right: 5px;
}

.entete_col a,
.entete_col_triAsc a,
.entete_col_triDesc a,
.triActions a {
    text-decoration: none;
    color: #000000;
    display: block;
    height: 10px;
}

.entete_col_triAsc a.tri_asc {
    display: none;
}

.entete_col_triDesc a.tri_desc {
    display: none;
}

.table thead tr th.entete_col_triAsc,
.table thead tr th.entete_col_triDesc {
    background-color: #f6f6f6;
    *background-color: #ffffff;
    background-image: -ms-linear-gradient(top, #f6f6f6, #ffffff);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f6f6f6), to(#ffffff));
    background-image: -webkit-linear-gradient(top, #f6f6f6, #ffffff);
    background-image: -o-linear-gradient(top, #f6f6f6, #ffffff);
    background-image: linear-gradient(top, #f6f6f6, #ffffff);
    background-image: -moz-linear-gradient(top, #f6f6f6, #ffffff);
    background-repeat: repeat-x;
}


/*---------------------------TABLE DONNEES---------------------------*/

.ligne_paire,
table.table tr:nth-child(even),
table.table_formulaire tr:nth-child(even) {
    background: #F2F2F2;
}

.ligne_impaire,
table.table tr:nth-child(odd),
table.table_formulaire tr:nth-child(odd) {
    background: #FFFFFF;
}

.ligne_paire:hover,
table.table tr:nth-child(even):hover,
table.table_formulaire tr:nth-child(even):hover,
.ligne_impaire:hover,
table.table tr:nth-child(odd):hover,
table.table_formulaire tr:nth-child(odd):hover {
    background: #cbe5f0;
}

.multicoche_tableau {
    width: 100%;
    height: 36px;
    margin-bottom: 15px;
}

.multicoche_tableau .fleche:before {
  content: "Actions pour la sélection : ";
  float: left;
  width: 15%;
  padding: 7px 0px;
}

.multicoche_tableau .lien,
.multicoche_tableau .lien_dropdown {
    float: right;
    width: 85%;
}

.multicoche_tableau .lien a:not(.lien_ajout),
.multicoche_tableau .lien button:not(.lien_ajout) {
    .btn();
    .btn-link();
}

.multicoche_tableau .lien a:not(.lien_ajout):last-child,
.multicoche_tableau .lien button:not(.lien_ajout):last-child {
    background: none;
}

.multicoche_tableau .lien a.lien_ajout,
.multicoche_tableau .lien_dropdown a.lien_ajout,
.multicoche_tableau .lien button.lien_ajout,
.multicoche_tableau .lien_dropdown button.lien_ajout {
    float: right;
}

.multicoche_tableau .lien:after{
  clear:both;
}

a.lien_ajout,
button.lien_ajout {
    .btn();
    .btn-add();
    // .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.multicoche_tableau .lien_dropdown a {
    text-decoration: none;
}

.multicoche_tableau .lien_dropdown .dropdown {
    width: 50%;
    float: left;
}

.multicoche_tableau .lien_dropdown .dropdown button {
    height: 25px;
}

.table,
.table_no_hover {
    border-collapse: collapse;
    // font-size:12px;
    border: solid 1px #dddddd;
    width: 100%
}

.table tr,
.table_no_hover tr {}

.table tr td,
.table_no_hover tr td {
    border-bottom: solid 1px #dddddd;
    padding: 5px 10px
}

.table thead tr th,
.table tfoot tr td,
.table tr.entete_table td,
.table tr.pied_table td,
.table_no_hover tr.entete_table td,
.table th,
.table_no_hover th {
    /*background:#ffffff url('../../img/entete_table_bg.gif') repeat-x top left;*/
    background-color: #ffffff;
    *background-color: #f6f6f6;
    background-image: -ms-linear-gradient(top, #ffffff, #f6f6f6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f6f6f6));
    background-image: -webkit-linear-gradient(top, #ffffff, #f6f6f6);
    background-image: -o-linear-gradient(top, #ffffff, #f6f6f6);
    background-image: linear-gradient(top, #ffffff, #f6f6f6);
    background-image: -moz-linear-gradient(top, #ffffff, #f6f6f6);
    background-repeat: repeat-x;
    border-top: solid 1px #dddddd;
    border-bottom: solid 1px #dddddd;
    padding: 9px 10px;
    font-weight: bold;
    line-height: 20px;
}

.table thead th label,
.table th label {
    font-weight: bold;
}

.table thead th.text_left,
.table thead td.text_left,
.table th.text_left,
.table td.text_left,
.text_left {
    text-align: left;
}

.table thead th.text_right,
.table thead td.text_right,
.table th.text_right,
.table td.text_right,
.text_right {
    text-align: right;
}

.table thead th.text_center,
.table thead td.text_center,
.table th.text_center,
.table td.text_center,
.text_center {
    text-align: center;
}

.table thead th.text_right,
.table thead td.text_right,
.table th.text_right,
.table td.text_right,
.text_right {
    text-align: right;
}

.table tr.filtre td {
    background: #eeeeee;
    border-bottom: solid 1px #dddddd;
    padding: 10px 10px;
    font-weight: bold;
    line-height: 20px;
}

.table tr.titre_table_espace td {
    background: #a4dfff;
    color: #000000;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
}

.table td.item {
    border: 0px;
    color: #62b4e1;
    font-size: 12px;
    font-weight: bold;
}

.table td.item .texte {
    color: #000000;
    font-weight: normal;
}

.table tr.no_border td {
    border: 0px;
}

.table tr.ligne_type_prestation {
    background-color: #CCC;
    font-weight: bold;
}

.table tr.ligne_type_prestation:hover td {
    background-color: #CCC;
    font-weight: bold;
}

.table tr.ligne_option {
    background-color: #0157A3;
    font-weight: bold;
    color: #FFFFFF;
}

.table tr.ligne_option:hover td {
    background-color: #0157A3;
    font-weight: bold;
    color: #FFFFFF;
}

.table tr td {
    height: 30px;
}

.col_sep {
    border-right: 1px solid #BBBBBB;
}

.table_couleur {
    border: solid 1px #DDDDDD;
    margin: 0px;
}

.table_couleur tr {}

.table_couleur td,
.table_couleur th {
    line-height: 20px;
    padding: 2px 5px;
    font-size: 11px;
}

.table_couleur td.entete_table,
.table_couleur tr.entete_table td {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    line-height: 20px;
    padding: 5px 5px;
}

.table_couleur2 {
    background-color: #eeeeee;
    margin: 0px;
    padding: 10px 0px;
}

.table_couleur2 tr {}

.table_couleur2 td,
.table_couleur2 th {
    line-height: 20px;
    padding: 5px 10px;
    font-size: 11px;
}

.table_couleur2 td.entete_table,
.table_couleur2 tr.entete_table td {
    color: #0c7e04;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    line-height: 20px;
    padding: 3px 5px;
}

.table_form_recherche {
    background: #ffffff;
}

table tr.row-checked {
    background: #81D4FA !important;
}

table tr.row-warning {
    background: #FFE082 !important;
}

table tr.row-danger {
    background: #EF9A9A !important;
}

table tr.row-success {
    background: #C5E1A5 !important;
}

table tr.row-info {
    background: #81D4FA !important;
}
