
input[type="button"]:not(.btn-primary),
input[type="submit"]:not(.btn-primary),
.btn:not(.btn-default)
.btn:not(.btn-primary)
.btn:not(.btn-actions)
{
  .btn();
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.btn-actions{
  font-size:14px;
  .button-variant(#ffffff; @color-primary; @color-primary);
}

.btn-youtube{
  background:#dd0000;
  color:#ffffff;
  font-size:20px;
  border-radius: 5px;
}

.btn-youtube:hover,
.btn-youtube:focus{
  background:#272727;
  color:#ffffff;
  text-decoration: none;
}

.btn-idea{
  background:#FFD600;
  color:#ffffff;
  font-size:14px;
}

.btn-panier{
  font-size:14px;
  .button-variant(#ffffff; @color-secondary; @color-secondary);
}

.quotation input.btn{
  background-color:#bbbbbb;
  line-height: 11px;
  border-radius: 6px;
}
.quotation input.btn:hover{
  color:#000000;
}

a.bouton{
background: none repeat scroll 0 0 @color-secondary;
border-radius: 3px 3px 3px 3px;
color: #FFFFFF;
display: inline-block;
font-size: 11px;
line-height: 20px;
padding: 0 10px 0;
text-decoration: none;
cursor:pointer;
}

a.bouton:hover{
text-decoration:underline;
}

.div_bouton_suite{
background:url('../../../img/bouton_suite.gif') no-repeat top left;
height:50px;
padding:0px 0px 0px 45px;
}

a.bouton_suite{
background:url('../../../img/bouton_suite.gif') no-repeat top right;
float: left;
color:#FFFFFF;
font-size:14px;
height:14px;
line-height:14px;
font-weight:bold;
text-decoration:none;
padding:14px 20px 22px 7px;
}
a.bouton_suite span{
padding:0px;
}


.btn-color-carre-kom{
  .button-variant(#ffffff; @color-carre-kom2; @color-carre-kom2);
}

.btn-green{
  .button-variant(#ffffff; @color-secondary; @color-secondary);
}

.btn-red{
  .button-variant(#ffffff; #F44336; #F44336);
}

.btn-success{
  .button-variant(#ffffff; #5cb85c; #4cae4c);
}

.btn-secondary{
  .button-variant(#ffffff; @color-secondary; @color-secondary);
}

.btn-add{
  .button-variant(#ffffff; @color-add; @color-add);
}

.btn-color-red {
  .button-variant(#ffffff, #F44336, #F44336);
}
.btn-color-pink {
  .button-variant(#ffffff, #E91E63, #E91E63);
}
.btn-color-purple {
  .button-variant(#ffffff, #9C27B0, #9C27B0);
}
.btn-color-deep-purple {
  .button-variant(#ffffff, #673AB7, #673AB7);
}
.btn-color-indigo {
  .button-variant(#ffffff, #3F51B5, #3F51B5);
}
.btn-color-blue {
  .button-variant(#ffffff, #2196F3, #2196F3);
}
.btn-color-light-blue {
  .button-variant(#ffffff, #03A9F4, #03A9F4);
}
.btn-color-cyan {
  .button-variant(#ffffff, #00BCD4, #00BCD4);
}
.btn-color-teal {
  .button-variant(#ffffff, #009688, #009688);
}
.btn-color-green {
  .button-variant(#ffffff, #4CAF50, #4CAF50);
}
.btn-color-light-green {
  .button-variant(#ffffff, #8BC34A, #8BC34A);
}
.btn-color-lime {
  .button-variant(#ffffff, #CDDC39, #CDDC39);
}
.btn-color-yellow {
  .button-variant(#ffffff, #FFEB3B, #FFEB3B);
}
.btn-color-amber {
  .button-variant(#ffffff, #FFC107, #FFC107);
}
.btn-color-orange {
  .button-variant(#ffffff, #FF9800, #FF9800);
}
.btn-color-deep-orange {
  .button-variant(#ffffff, #FF5722, #FF5722);
}
.btn-color-brown {
  .button-variant(#ffffff, #795548, #795548);
}
.btn-color-grey {
  .button-variant(#ffffff, #9E9E9E, #9E9E9E);
}
.btn-color-blue-grey {
  .button-variant(#ffffff, #607D8B, #607D8B);
}
.btn-color-black {
  .button-variant(#ffffff, #000000, #000000);
}
.btn-color-white {
  .button-variant(#ffffff, #FFFFFF, #FFFFFF);
}

.dropdown-menu-min-width {
    min-width: 184px !important;
}
