/*---------------------------FIL ARIANE---------------------------*/

.breadcrumb{
margin-bottom:0px;
padding: 8px 15px 8px 0px;
background-color: transparent;
}

#ariane{
padding:0px;
font-size:12px;
color:#000000;
}

#ariane a {
font-size:12px;
color:#000000;
text-decoration:none;
}

.container-title-page{
margin-bottom: 10px;
}
