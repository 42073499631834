/*---------------- AGENDA -----------------*/

.event_agenda{
border: 1px solid #DDDDDD;
font-size: 11px;
padding: 1%;
margin-bottom:10px;
float:left;
clear:both;
width:100%;
}

.event_agenda h2{
color: #0c7e04;
margin:3px 0px 5px 0px;
}

.event_date{
font-size:12px;
font-weight:bold;
}

.event_actions{
padding:5px;
float:left;
width:100%;
}

.event_boutons_detail{
float:left;
width:30%;
}

.event_inscription{
float:left;
width:30%;
}

.event_export{
float:right;
margin-right:10px;
}

.event_export a{
font-size:12px;
text-decoration:none;
}

.event_export img{
position: relative;
bottom: -3px;
}

.event_detail, .event_detail p{
font-size:11px;
}

.lien_inscr_refus{
background-color:#f44336;
padding:2px 5px 2px 20px;
}

.lien_inscr_attente{
background-color:#ff9800;
padding:2px 5px 2px 20px;
}

.lien_inscr_accept{
background-color: #4caf50;
padding:2px 5px 2px 20px;
}

.lien_inscr_refus a, .lien_inscr_attente a, .lien_inscr_accept a{
text-decoration:none;
font-size:12px;
}

.agenda_liste_ligne{
clear:both;
padding:5px 0px;
font-size:12px;
border-bottom:1px solid #DDDDDD;
}

.agenda_liste_ligne:hover{
background:#BACEFF;
}

.agenda_liste_ligne .legende{
height:20px;
width:5px;
float:left;
margin-right:10px;
}

.agenda_liste_ligne .date{
width:30%;
display:block;
float:left;
font-size:11px;
padding:3px 0px 0px 0px;
}

#zone_agenda{
float:left;
width:68%;
margin-right:10px;
}

#zone_tache{
margin-left:10px;
float:left;
width:29%;
}

.tache_chk{
float:left;
}

.tache_content{
float:left;
padding:2px;
width:50%;
}

.tache_content a{
text-decoration:none;
color:#000000;
}

.tache_content a:hover{
text-decoration:underline;
}

.tache{
float:left;
clear:both;
width:100%;
font-size:12px;
padding: 1% 1% 2% 1%;
border-bottom: 1px solid #DDDDDD;
}

.tache:hover{
background:#BACEFF;
}

.tache .legende{
height:18px;
width:5px;
float:left;
margin-right:10px;
}

.tache_date{
color:#0c7e04;
float:left;
font-size:12px;
padding:3px;
}

.tache_delete{
float:right;
}

.tache_form{
clear:both;
}

.ajout_direct{
text-decoration:none;
color:#0c7e04;
font-size:14px;
font-weight:bold;
padding:10px 25px 10px 10px;
clear:both;
margin-bottom:5px;
float:right;
cursor:pointer;
}

#rdv_form{
display:none;
float:left;
width:100%;
}


#liste_taches{
padding:5px 0px;
overflow:auto;
max-height: 300px;
clear:both;
}

.lien_tache_undated{
font-size:11px;
cursor:pointer;
}

#id_tache_undated{
padding:5px 0px;
}
