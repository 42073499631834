/*---------------------------GLOBAL---------------------------*/
html, body {height: 100%;}

/*Opera Fix*/
body:before {/* thanks to Maleika (Kohoutec)*/
content:"";
height:100%;
float:left;
width:0;
margin-top:-32767px;/* thank you Erik J - negate effect of float*/
}

html{
margin:0px;
}

body{
background:#f6f9fa;
font-family:Verdana,Arial;
margin:0px;
width:100%;
height:100%;
}

