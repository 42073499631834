.text-red {
  color: #F44336;
}
.text-pink {
  color: #E91E63;
}
.text-purple {
  color: #9C27B0;
}
.text-deep-purple {
  color: #673AB7;
}
.text-indigo {
  color: #3F51B5;
}
.text-blue {
  color: #2196F3;
}
.text-light-blue {
  color: #03A9F4;
}
.text-cyan {
  color: #00BCD4;
}
.text-teal {
  color: #009688;
}
.text-green {
  color: #4CAF50;
}
.text-light-green {
  color: #8BC34A;
}
.text-lime {
  color: #CDDC39;
}
.text-yellow {
  color: #FFEB3B;
}
.text-amber {
  color: #FFC107;
}
.text-orange {
  color: #FF9800;
}
.text-deep-orange {
  color: #FF5722;
}
.text-brown {
  color: #795548;
}
.text-grey {
  color: #9E9E9E;
}
.text-blue-grey {
  color: #607D8B;
}
.text-black {
  color: #000000;
}
.text-white {
  color: #FFFFFF;
}

.text-gold {
  color: @color-gold;
}

.text-padel{
  color: @color-padel;
}

.bg-red {
  background-color: #F44336;
}
.bg-pink {
  background-color: #E91E63;
}
.bg-purple {
  background-color: #9C27B0;
}
.bg-deep-purple {
  background-color: #673AB7;
}
.bg-indigo {
  background-color: #3F51B5;
}
.bg-blue {
  background-color: #2196F3;
}
.bg-light-blue {
  background-color: #03A9F4;
}
.bg-cyan {
  background-color: #00BCD4;
}
.bg-teal {
  background-color: #009688;
}
.bg-green {
  background-color: #4CAF50;
}
.bg-light-green {
  background-color: #8BC34A;
}
.bg-lime {
  background-color: #CDDC39;
}
.bg-yellow {
  background-color: #FFEB3B;
}
.bg-amber {
  background-color: #FFC107;
}
.bg-orange {
  background-color: #FF9800;
}
.bg-deep-orange {
  background-color: #FF5722;
}
.bg-brown {
  background-color: #795548;
}
.bg-grey {
  background-color: #9E9E9E;
}
.bg-blue-grey {
  background-color: #607D8B;
}
.bg-black {
  background-color: #000000;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-coral {
    background-color: coral;
}

.bg-primary{
  background-color: @color-primary;
}

.bg-secondary{
  background-color: @color-secondary;
}

.bg-tertiary{
  background-color: @color-tertiary;
}

.bg-gold {
  background-color: @color-gold;
}

.bg-hydroway {
  background-color: @color-hydroway;
}

.bg-stabiway {
  background-color: @color-stabiway;
}

.bg-baltazar {
  background-color: @color-baltazar;
}

.bg-endersol {
  background-color: @color-endersol;
}

.bg-highlighted {
  background-color: rgba(248, 249, 190, 0.4) !important;
}

.bg-yellow.label {
  color:black;
}

.bg-padel{
  background-color: @color-padel;
}