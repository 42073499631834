.wrap {
min-height: 100%;
height: auto !important;
height: 100%;
margin: 0 auto -57px;
}

.wrapper {
padding-bottom: 57px;
}

#deco{
min-height: 100%;
margin:0px;
}

/*---------------------------PRINCIPAUX CONTENEURS---------------------------*/

#site{
width:100%;
margin-left:auto;
margin-right:auto;
margin-top:0px;
margin-bottom:0px;
padding:0px;
}

#bas{
background: #94a74b;
color:#ffffff;
padding:0% 1%;
width:100%;
z-index:10;
height: 57px;
clear:both;
}

#content{
width:82%;
padding:0px;
float:left;
// font-family:Arial;
}

#content ul{
font-size:12px;
padding-left:10px;
}

#content ul li{
font-size:12px;
padding-left:10px;
padding:5px;
list-style-position:inside;
}

#content_right{
float:left;
width:16%;
margin-left:1%;
margin-bottom:10px;
}

#full_content{
width:100%;
border:solid 1px #b2b2b2;
background:#FFFFFF;
padding:1%;
float:left;
margin:10px 0px;
}

#PSR_print{
width:100%;
}


/*------------------------- CLASSES GLOBALES ---------------------*/

.hide{
display:none;
}

.titre {
color:#0c7e04;
font-size:16px;
font-weight:bold;
}

.page_principale {
float:left;
width:100%;
}


a.info {
color:#5C8EC1;
font-size:12px;
font-weight:bold;
position:relative;
text-decoration:none;
}

a.info span {
display:none;
}

a.info:hover {
background:transparent none repeat scroll 0 0;
cursor:help;
z-index:500;
}

a.info:hover span {
background-color:#FAFAFA;
border:3px solid #CCCCCC;
color:black;
display:inline;
font-size:12px;
font-weight:normal;
left:20px;
padding:5px;
position:absolute;
top:30px;
white-space:nowrap;
}

a.voir_suite{
color:black;
height:22px;
font-size:11px;
text-decoration:none;
padding:0px 0px 0px 22px;
}
a.voir_suite span{
padding:7px 10px 7px 7px;
float: left;
line-height: 8px;
}




/*------------------------ Module ---------------------*/
/* Gestion des modules sur menu*/
.type_module{
font-size:14px;
cursor:pointer;
}

.liste_module_open{

}

.liste_module_close{
display:none;
}

.identification {
border:2px solid #DDDDDD;
padding:0 0 0 150px;
}

/*----------------------------- POPUP ----------------*/
h1.popup_titre{
/*
font-size:16px;
font-weight:bold;
line-height:13px;
color:#000000;
*/
padding:0px 20px 10px 20px;
margin:0;
}

div.popup_content{
font-size:11px;
}

#indicateur-container{height:400px;width:350px; color:#000000; background-color:#ffffff; border:4px solid #0c7e04; padding:12px;z-index:10;}
#indicateur-container .simplemodal-data {padding:8px;color:#000000;z-index:10;}
#indicateur-container code {background:#141414; border-left:3px solid #65B43D; color:#bbb; display:block; font-size:12px; margin-bottom:12px; padding:4px 6px 6px;z-index:10;}
#indicateur-container a.modalCloseImg {background:url(../../../../../Produit/Script/simplemodal/css/img/x.png) no-repeat; width:25px; height:29px; display:inline; z-index:3200; position:absolute; top:-15px; right:-16px; cursor:pointer;}
#indicateur-container h3 {color:#84b8d9;z-index:10;}

#workflowAction-container{height:500px;width:600px; color:#000000; background-color:#ffffff; border:4px solid #0c7e04; padding:12px;z-index:10;}
#workflowAction-container .simplemodal-data {padding:8px;color:#000000;z-index:10;}
#workflowAction-container code {background:#141414; border-left:3px solid #65B43D; color:#bbb; display:block; font-size:12px; margin-bottom:12px; padding:4px 6px 6px;z-index:10;}
#workflowAction-container a.modalCloseImg {background:url(../../../../../Produit/Script/simplemodal/css/img/x.png) no-repeat; width:25px; height:29px; display:inline; z-index:3200; position:absolute; top:-15px; right:-16px; cursor:pointer;}
#workflowAction-container h3 {color:#84b8d9;z-index:10;}

#comm_gestion_achats_produit-container{height:150px;width:500px; color:#000000; background-color:#ffffff; border:4px solid #0c7e04; padding:12px;z-index:10;}
#comm_gestion_achats_produit-container .simplemodal-data {padding:8px;color:#000000;z-index:10;}
#comm_gestion_achats_produit-container code {background:#141414; border-left:3px solid #65B43D; color:#bbb; display:block; font-size:12px; margin-bottom:12px; padding:4px 6px 6px;z-index:10;}
#comm_gestion_achats_produit-container a.modalCloseImg {background:url(../../../../../Produit/Script/simplemodal/css/img/x.png) no-repeat; width:25px; height:29px; display:inline; z-index:3200; position:absolute; top:-15px; right:-16px; cursor:pointer;}
#comm_gestion_achats_produit-container h3 {color:#84b8d9;z-index:10;}

.admin_menu_layer {
color:#0c7e04;
font-size:12px;
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
padding:10px;
margin:5px;
}
.admin_menu_layer_select {
color:#0c7e04;
font-size:14px;
font-weight:bold;
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
padding:10px;
margin:5px;
}

.admin_langue {
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
background-color:#eeeeee;
width:100px;
margin:5px;
}
.admin_langue_select {
font-weight:bold;
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
width:150px;
margin:5px;
}



/*------------ STATISTIQUES --------------*/

.chart_loading {
position:absolute;
bottom:0%;
left:0%;
height:10%;
width:10%;
padding:0;
margin:0;
z-index:1000;
text-align:center;
}

.outil_donnees{
background:#EEEEEE;
padding:5px;
margin:0px 5px;
text-decoration:none;
}

/*------------------ MUR ----------------*/

.mur_commentaires {
margin:0px;
}

.mur_commentaires tr{
}

.mur_commentaires td, .mur_commentaires th{
line-height:20px;
padding:2px 5px;
font-size:11px;
background-color:#EFFDFF;
}

.mur_commentaires td.entete_table, .mur_commentaires tr.entete_table td{
color:#0c7e04;
font-size:14px;
font-weight:bold;
text-decoration:none;
line-height:20px;
padding:5px 5px;
}





.controle_agenda{
border:solid 1px #CCCCCC;
background:#EFEFEF;
text-decoration:none;
color:#000000;
font-size:12px;
font-weight:bold;
padding:10px;
float:left;
}
.controle_suiv, .controle_prec{
margin:5px 10px 5px 0px;
}
.controle_auj{
margin:5px 0px 5px 30px;
}

/*---------------------- ONGLET -------------------*/
.onglet_form {
color:#000000;
font-size:12px;
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
padding:10px;
margin:2px;
float:left;
text-decoration:none;
}

.onglet_form_selected {
color:#0c7e04;
font-size:14px;
font-weight:bold;
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
padding:10px;
margin:2px;
float:left;
text-decoration:none;
}

/******************* MISE EN PAGE DES ARTICLES DANS LES PAGES *******************/

.document {
padding:5px 5px 5px 5px;
clear:both;
}

.texte_document{
clear:both;
padding:5px 0px 5px 0px;
}

.clear{
clear:both;
}

div.zone_simple{
padding:10px 0px;
}

/*---------------------------OPTIONS LOGO PDF---------------------------*/

#options_logo{
float:left;
width:100%;
margin-bottom:10px;
border:solid 1px #b2b2b2;
border-radius:10px;
}

#options_logo .titre_action{
float:left;
width:100%;
color:#000000;
font-size:14px;
font-weight:bold;
border-bottom:solid 1px #cccccc;
height:50px;
line-height:50px;
padding:0px 0px 5px 0px;
vertical-align:middle;
}

#options_logo .libelle_action{
padding:10px 0px 10px 10px;
float:left;
height:30px;
line-height:30px;
}

#options_logo .contenu{
float:left;
font-size:12px;
}

#options_logo .contenu .image_bloc_promo{
height:auto;
width:auto ;
text-align: center;
}

#options_logo .contenu .image_bloc_promo img {
position:relative;
top:50%;
left:50%;
margin:5px 0 5px -75%;
}

#options_logo {
text-align: center;
}

#txt_logo {
padding : 10px 0px 5px 10px; float:left;
}

/*---------------------------DEMANDE CONTACT---------------------------*/
.type_demande_applicateur{
background:#2E9CB9;
}

.type_demande_prescripteur{
background:#6A4B8E;
}

.type_demande_presse{
background:#80A23B;
}

.espace_verification{
border-radius: 10px;
border:2px solid #01415E;
padding:10px;
}

.espace_verification span{
font-size:16px;
font-weight:bold;
}

.status_etat_demande{
border-radius: 10px;
padding:10px;
width:230px;
height:25px;
margin: 0px 0px 10px 0px;
}

.status_etat_demande span{
font-weight:bold;font-size: 13px;
}

.fiche_demande_infos{
float:left;width:58%;
margin-right: 2%;
}

.fiche_demande_actions{
float:left;width:40%;
}

.green{
color:#468847;
}
.red{
color:#CC0000;
}

.bg_green{
background:#66BB6A !important;
}

.bg_red{
background:#EF5350 !important;
}

.bg_gris{
background:#dddddd !important;
}

/*--- BLOC IDEE ---*/
#dsh_bloc_idee{
float:left; width:100%; border:solid 2px #0C7E04; margin:10px 0px 15px 0px;
}
#dsh_bloc_idee h2{
float:left; background:#E3E0DB; height:30px; line-height:30px; color:#0C7E04; margin:-16px 0px 0px 10px; padding:0px 10px 0px 10px;
}
#dsh_bloc_idee .contenu{
float:left; width:100%; padding:2%;
}
#dsh_bloc_idee .item{
float:left; width:100%;
}
#dsh_bloc_idee .item span{
float:left; padding:7px; line-height:8px;
}
.contenu{
// font-size:12px;
}
.float-right{
float:right;
}

.label_notif{
border-radius: 12px;
display: inline-block;
font-size: 12px;
font-weight: bold;
height: 20px;
padding: 4px 2px 0px 1px;
text-decoration: none;
width: 20px;
}

.label_jdm, .label_jdm:hover{
background:#0C7E04;
color:#ffffff;
}

.label_jdm_bleu, .label_jdm_bleu:hover{
background:#01415e;
color:#ffffff;
}

.documents_generaux{
width:45%;
float:left;
border-radius: 12px;
border:solid 1px #dedede;
margin: 10px;
padding: 10px;
}
.documents_projets{
width:45%;
float:left;
border-radius: 12px;
border:solid 1px #dedede;
margin: 10px;
padding: 10px;
}
.documents_projets em,
.documents_generaux em{
margin-bottom: 15px;
}

.popup_header{
margin: 10px 0px;
}

.radio-big{
font-size: 16px;
display: inline-block;
margin-bottom: 10px;
}

.label-mcl{
border-radius:4px;
color:#ffffff;
background:#DD0000;
font-weight:bold;
padding:5px;
display:inline-block;
}

.tabs {
margin-top: 10px;
}

.tab {
background:#ededed;
list-style:none;
display:inline-block;
border:solid 1px #dedede;
padding:10px !important;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.tab.active {
border-bottom:none;
background: white;
padding-bottom:11px !important;
}

.fake-tab {
background: none;
border: none;
color: #838383;
}

.tab a {
text-decoration: none;
color: #838383;
}

.tab.active a {
text-decoration: none;
color: black;
}

.tab-content {
margin-top:-1px;
border:solid 1px #dedede;
padding:20px;
}


.tabs-xl{
border-bottom: 1px solid #dedede;
}

.tabs-xl li{
font-size:14px !important;
text-align: center;
min-width:200px;
}

.tabs-xl li.active{
font-weight: bold;
}

.rowChoixLivraison{
border-bottom:1px solid #e3e3e3;
}

.rowChoixLivraison:nth-child(2n){
background:#f7f7f7;
}

.rowChoixLivraison h4{
color:#01415e;
}

.input-xs{
width:30px;
}

.etat_commande_global{
font-weight: bold;
color:#FFFFFF;
text-shadow: 1px 1px #333333;
}

.demande_confirmation{
background:#fbfbfb none repeat scroll 0 0;
border: 1px solid #dedede;
border-radius: 3px 3px 3px 3px;
margin-bottom: 15px;
text-align: center;
padding: 10px;
}

.labelAmount{
font-weight:normal;
font-size:14px;
line-height:24px;
}

.amount{
font-weight:normal;
font-size:16px;
line-height:24px;
}

.vaTop{
vertical-align: top;
}

.w100{
width:90%;
}

.libelleLigneCommande{
font-size: 14px;
margin-top: 10px;
display:inline-block;
}

.valign-middle{
vertical-align:middle;
}

ul.chosen-choices{
min-height:30px;
line-height:30px;
}

tr.prepare{
  background:#9CCC65 !important;
}

tr.incomplete{
  background:#FF9800 !important;
}

h4.modal-title{
font-weight:500;
color:#333333;
font-size:18px;
}

.modal-footer .btn{
  margin:0;
}
