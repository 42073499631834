/* Carré kom */

.field-carre-kom{
  background:@color-carre-kom1;
  display: inline-block;
  border:1px solid @color-carre-kom1;
  padding:3px 5px;
  color:#ffffff;
}

.checkbox-carre-kom{
  background-color:@color-carre-kom1;
  padding: 10px;

  label{
    color:#ffffff;
    font-size:13px;

    input[type="checkbox"] {
      margin-top: 0;
    }
  }
}

.prix-carre-kom{
  font-size:16px;
  color:@color-carre-kom1;
}

/* Table credit carre kom */
.table-carre-kom{
  width:100%;
  border-collapse: separate;
  border-spacing: 5px 8px;
}

.cell-carre-kom{
  background:@color-carre-kom1;
  border:1px solid black;
  height:10px;
  width:10px;
}

.cell-carre-kom-used{
  background:red;
}
