/*-------------------------- ICONFILE -------------------*/
/* Style les liens vers pièces jointes avec icone selon extension */

a.iconfile{
background:url('@{global-img-path}/iconfile/iconfile_default.png');
height:52px;
width:52px;
display:block;
text-align:center;
margin:0;
padding:0;

}

a.iconfile[href$=".pdf"]{
background:url('@{global-img-path}/iconfile/iconfile_pdf.png') no-repeat center right;
}

a.iconfile[href$=".ppt"]{
background:url('@{global-img-path}/iconfile/iconfile_ppt.png') no-repeat center right;
}

a.iconfile[href$=".xls"], a.iconfile[href$=".xlsx"]{
background:url('@{global-img-path}/iconfile/iconfile_excel.png') no-repeat center right;
}

a.iconfile[href$=".doc"], a.iconfile[href$=".docx"], a.iconfile[href$=".odt"]{
background:url('@{global-img-path}/iconfile/iconfile_word.png') no-repeat center right;
}
