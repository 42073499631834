/*---------------------------PHOTOTHEQUE---------------------------*/
#filtre_chosen_accessoire ul {
padding:0;
}

#filtre_chosen_accessoire ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_decoration ul {
padding:0;
}

#filtre_chosen_decoration ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_couleur ul {
padding:0;
}

#filtre_chosen_couleur ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_marche ul {
padding:0;
}

#filtre_chosen_marche ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_motif ul {
padding:0;
}

#filtre_chosen_motif ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_produit ul {
padding:0;
}

#filtre_chosen_produit ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

#filtre_chosen_destination ul {
padding:0;
}

#filtre_chosen_destination ul.chzn-choices li{
padding:3px 20px 3px 5px;
}

.photos {
float:left; width:100%;
}

.photo_vignette:nth-child(4n+1){
clear:both;
}

.photo_vignette img{
// max-height: 150px;
}
