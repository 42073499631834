/*---------------------------MENU---------------------------*/

#menu_principal{
clear:both;
float:left;
width:100%;
padding:0% 1%;
z-index:10;
background:#000;
border-bottom: 5px solid #666;
}

#menu{
list-style:none;
height:31px;
padding:0px 0px;
margin:0px;
white-space:nowrap;
z-index:10;
}

#menu_principal.menu_2lignes{
height: 62px;
}


#menu li{
margin:0;
display:inline;
float:left;
z-index:10;
}

#menu li a{
display:block;
padding:0px 2px;
line-height:31px;
font-weight:bold;
font-size:12px;
color:#ffffff;
text-decoration:none;
z-index:10;
}

#menu li a.sub{
background:url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  12px;
z-index:10;
}

#menu li a.sub:hover{
background:@color-primary url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  12px;
color:#FFFFFF;
z-index:10;
}

#menu li a .deb{
height:30px;
padding:9px 0px;
z-index:10;
}
#menu li a .fin{
width:6px;
padding:9px 0px;
z-index:10;
}
#menu li a .milieu{
width:6px;
padding:9px 10px;
z-index:10;
}

#menu li.onglet_selected > a, #menu li.onglet_selected > a:hover, #menu li > a:hover, #menu li > a.trace{
  background:@color-primary;
}

#menu li.onglet_selected a .deb, li a:hover .deb{
/*background: url('@{img-path}/menu_deb.gif') no-repeat bottom left;*/
}
#menu li.onglet_selected a .fin, li a:hover .fin{
/*background: url('@{img-path}/menu_fin.gif') no-repeat bottom right;*/
}
#menu li.onglet_selected a .milieu, li a:hover .milieu{
/*background: url('@{img-path}/menu_milieu.gif') repeat-x bottom left;*/
}

#menu ul{
list-style:none;
background:#ffffff;
position:absolute;
display:none;
padding:0px;
margin:0px 0px 0px 0px;
width:260px;
z-index:10;
border-bottom:solid 1px @color-primary;
border-left:solid 1px @color-primary;
border-right:solid 1px @color-primary;
}

#menu ul li{
float:left;
z-index:10;
padding:5px 15px;
width:100%;
}

#menu ul li:hover {
background:#efefef;
}

#menu ul li a{
z-index:10;
display:block;
padding:5px 20px;
line-height:20px;
text-decoration:none;
color:#000000;
font-weight:normal;
font-size:11px;
border:none;
}

#menu ul li a:hover{
color:#000000;
background:#efefef;
z-index:10;
}

#menu ul li a.sub{
background:url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  12px;
z-index:10;
}

#menu ul li a.sub:hover{
background:#efefef url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  12px;
color:#000000;
z-index:10;
}

#menu ul li span.img_icone_left img{
  max-width:24px;
  max-height:24px;
}

#menu ul li ul{
list-style:none;
background:#ffffff;
left:100%;
width:260px;
margin:-35px 0px 0px -1px;
padding:0px;
border-top:solid 1px #0c7e04;
border-left:none;
border-right:solid 1px #0c7e04;
z-index:10;
}

#menu ul li ul li{
float:left;
padding:5px 15px;
width:100%;
z-index:10;
}

#menu ul li ul li:hover{
background:#efefef;
}

#menu ul li ul li a{
display:block;
padding:5px 20px;
line-height:20px;
text-decoration:none;
color:#000000;
font-weight:normal;
font-size:11px;
border:none;
z-index:10;
}

#menu ul li ul li a:hover{
color:#000000;
background:#efefef;
z-index:10;
}

#menu a:hover,#menu a:active,#menu a:focus{
z-index:10;
}

#menu .montre{
display:block;
z-index:10;
}

#menu li a.trace{
background:url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  14px;
color:#FFFFFF;
z-index:10;
}
#menu li a.trace .deb{
/*background: url('@{img-path}/menu_deb.gif') no-repeat bottom left;*/
}
#menu li a.trace .fin{
/*background: url('@{img-path}/menu_fin.gif') no-repeat bottom right;*/
}
#menu li a.trace .milieu{
/*background: url('@{img-path}/menu_milieu.gif') repeat-x bottom left;*/
}

#menu li ul li a.trace{
background:url('@{img-path}/menu_deroulant_fleche.gif') no-repeat 95%  12px;
color:#000000;
}


/**********************/

.navbar{
  border: none;
}

.navbar-main {
background:#000;
border-radius: 0;
margin-bottom: 0px;
min-height: 40px;
}

.navbar-main .navbar-brand{
  color:#ffffff;
}

.navbar-main .icon-bar{
  background:#ffffff;
}

.navbar-main .picto img{
  max-width:24px;
  max-height:24px;
}

.navbar-main li a{
font-size:14px;
color:#ffffff;
text-decoration:none;
padding: 10px 15px;
}


.navbar-main li a.active, .navbar-main li a:hover, .navbar-main li a:focus{
  background:#666;
}

.navbar-main .open>a, .navbar-main .open>a:hover, .navbar-main .open>a:focus{
 background:#666;
}

.navbar-main li ul li a{
  color:#000000;
  font-weight: normal;
  padding-top:10px;
  padding-bottom:10px;
}

.navbar-main li ul li a:hover, .navbar-main li.active ul li.active > a{
  color:#ffffff;
  background:#666;
}

.navbar-main li ul li ul{
  list-style-type:none;
  padding-left:0px;
}

.navbar-main li ul li ul li{
  padding: 0;
}

.navbar-main li ul li ul li a{
  display: block;
}

.navbar-main li ul li ul li a:hover, .navbar-main li.active ul li.active ul li.active > a{
  color:#ffffff;
  background:@color-primary;
}


.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.icon-menu{
  font-size:16px;
}
