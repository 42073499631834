.panel-box-idea{
  border:3px solid #0c7e04;
}

.panel-box-idea .panel-heading{
  background-color:#0c7e04;
  color:#ffffff;
  border-radius:0;
}

.panel-title{
font-size: 18px;
}

.panel-label{
  color:#000000;
}

.panel-label:hover{
  text-decoration:none;
}

.panel-each-applicateur{
  .panel-heading{
    background-color: #0c7e04;
    color: #fff;
  }
}

.panel-body-fixed-height-360{
  max-height:360px;
  overflow:auto;
}

.fixed-height-360{
  max-height:360px;
  overflow-x:hidden;
  overflow-y: auto;
}

.panel-hydroway{
  .panel-variant(@panel-default-border; #ffffff; @color-hydroway; @color-hydroway);
}

.panel-baltazar{
  .panel-variant(@panel-default-border; #ffffff; @color-baltazar; @color-baltazar);
}

.panel-stabiway{
  .panel-variant(@panel-default-border; #ffffff; @color-stabiway; @color-stabiway);
}

.panel-endersol{
  .panel-variant(@panel-default-border; #ffffff; @color-endersol; @color-endersol);
}

.separator-border {
  border-bottom: 0.5px solid #5555;
  margin-bottom: 15px;
}
