/*---------------------------BANDEAU---------------------------*/

#banner{
background: url('@{img-path}/bandeau_logo.png') no-repeat top left;
width:100%;
padding:0px 0px 0px 0px;
margin: 0;
}

#logo{
  padding:0;
}

#outil{
  padding:15px;
  font-size: 12px;
  color:#ffffff;
}

#photo_profil{
  height:80px;
  width: 80px;
  border-radius: 40px;
}

.container-youtube{
clear: both;
padding-right: 20px;
}

.btn-account{
  margin: 10px 0 0 0 0;
  .button-variant(#ffffff; #94a74b; #94a74b);
}
