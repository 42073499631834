#alerte {
width:100%;
background-color:#eee;
color:#000000;
padding: 6px 3% 12px 3%;
font-size:13px;
}

#alerte a{
padding:7px 0px 3px 35px;
background: url('../../../img/bug.png') no-repeat top left;
text-decoration:none;
line-height:20px;
color:#D83A22;
}

#alerte_uti_secondaire{
width:100%;
background-color:#444;
color:#ffffff;
padding: 7px 3% 7px 3%;
font-size:11px;
}

#alerte_uti_secondaire a{
background:#000000;
border-radius:5px;
padding:7px;
text-decoration:none;
line-height:20px;
color:#FFFFFF;
}

.popup_alerte{
position:absolute;
border:2px solid #999999;
background-color: #FFFFFF;
font-size:12px;
padding:0px;
}

.popup_alerte  .entete{
background-color: #eeeeee;
color:#ffffff;
line-height:30px;
padding:5px 5px;
}

.message_alerte{
width:100%;font-size:12px;margin-bottom:10px;
}
.message_close{
background-position:0px 0px;width:10px;height:10px;position:relative;top:-2px;line-height:18px;float:right;text-decoration:none;
}
.message_close:hover{
background-position:0px -10px;width:10px;height:10px;
}
.message_erreur{
color:#CC0000;border:1px solid #CC0000;background:#F2DEDE;padding:10px;
}
.message_valide{
color:#468847;border:1px solid #468847;background:#DFF0D8;padding:10px;
}
.message_info{
color:#3a87ad;border:1px solid #3a87ad;background:#d9edf7;padding:10px;
}
.message_warning{
color:#C09853;border:1px solid #C09853;background:#FCF8E3;padding:10px;
}
