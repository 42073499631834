/*---------------- LEGENDE ---------------*/

a.legende {
color:#5C8EC1;
font-size:12px;
font-weight:bold;
position:relative;
text-decoration:none;
}

a.legende div.legende-content {
display:none;
text-align:left;
}

a.legende span.legende-titre {
display:block;
color:#0c7e04;
font-weight:normal;
font-size:15px;
margin:0px;
padding:0px;
text-align:left;
}

a.legende:hover {
background:transparent none repeat scroll 0 0;
cursor:help;
z-index:500;
}

a.legende:hover div.legende-content {
background-color:#FAFAFA;
border:3px solid #0c7e04;
color:black;
display:inline;
font-size:12px;
font-weight:normal;
left:20px;
padding:5px;
position:absolute;
top:30px;
white-space:nowrap;
}

div.legend_box_color{
float: left;
height: 18px;
margin-right: 10px;
width: 5px;
}
