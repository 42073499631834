/*
 * 5 colonnes
 */
.col-lg-20p {
    .make-lg-column(2.4);
}
.col-md-20p {
    .make-md-column(2.4);
}
.col-sm-20p {
    .make-sm-column(2.4);
}

/*
 * 5 colonnes push
 */
.col-lg-push-20p {
    .make-lg-column-push(2.4);
}
.col-md-push-20p {
    .make-md-column-push(2.4);
}
.col-sm-push-20p {
    .make-sm-column-push(2.4);
}

/*
 * 5 colonnes pull
 */
.col-lg-pull-20p {
    .make-lg-column-pull(2.4);
}
.col-md-pull-20p {
    .make-md-column-pull(2.4);
}
.col-sm-pull-20p {
    .make-sm-column-pull(2.4);
}

/*
 * 5 colonnes offset
 */
.col-lg-offset-20p {
    .make-lg-column-offset(2.4);
}
.col-md-offset-20p {
    .make-md-column-offset(2.4);
}
.col-sm-offset-20p {
    .make-sm-column-offset(2.4);
}

.no-gutters {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
