/** Bootstrap utilities margin padding */
.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.m-a-0 {
  margin: 0 0 !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-a-1 {
  margin: 1rem 1rem !important;
}
.m-t-1 {
  margin-top: 1rem !important;
}
.m-r-1 {
  margin-right: 1rem !important;
}
.m-b-1 {
  margin-bottom: 1rem !important;
}
.m-l-1 {
  margin-left: 1rem !important;
}
.m-x-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.m-y-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.m-a-2 {
  margin: 1.5rem 1.5rem !important;
}
.m-t-2 {
  margin-top: 1.5rem !important;
}
.m-r-2 {
  margin-right: 1.5rem !important;
}
.m-b-2 {
  margin-bottom: 1.5rem !important;
}
.m-l-2 {
  margin-left: 1.5rem !important;
}
.m-x-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.m-y-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.m-a-3 {
  margin: 3rem 3rem !important;
}
.m-t-3 {
  margin-top: 3rem !important;
}
.m-r-3 {
  margin-right: 3rem !important;
}
.m-b-3 {
  margin-bottom: 3rem !important;
}
.m-l-3 {
  margin-left: 3rem !important;
}
.m-x-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.m-y-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.p-a-0 {
  padding: 0 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-a-1 {
  padding: 1rem 1rem !important;
}
.p-t-1 {
  padding-top: 1rem !important;
}
.p-r-1 {
  padding-right: 1rem !important;
}
.p-b-1 {
  padding-bottom: 1rem !important;
}
.p-l-1 {
  padding-left: 1rem !important;
}
.p-x-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.p-y-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.p-a-2 {
  padding: 1.5rem 1.5rem !important;
}
.p-t-2 {
  padding-top: 1.5rem !important;
}
.p-r-2 {
  padding-right: 1.5rem !important;
}
.p-b-2 {
  padding-bottom: 1.5rem !important;
}
.p-l-2 {
  padding-left: 1.5rem !important;
}
.p-x-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.p-y-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.p-a-3 {
  padding: 3rem 3rem !important;
}
.p-t-3 {
  padding-top: 3rem !important;
}
.p-r-3 {
  padding-right: 3rem !important;
}
.p-b-3 {
  padding-bottom: 3rem !important;
}
.p-l-3 {
  padding-left: 3rem !important;
}
.p-x-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.p-y-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}


.d {
    &-block{
        display:block;
    }

    &-inline-block {
        display: inline-block;
    }

    &-none {
        display: none;
    }

    &-flex {
        display: flex;
    }
}

.fw {
    &-bold {
        font-weight: bold;
    }
}

.flex-row {
    flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }

    &-between {
        justify-content: space-between;
    }

    &-even {
        justify-content: space-evenly;
    }
}

.align-items {
    &-center {
        align-items: center;
    }
}

.visibility {
    &-visible {
        visibility: visible;
    }

    &-hidden {
        visibility: hidden;
    }
}

.w {
    &-25 {
        width: 25%;
    }

    &-30 {
        width: 30%;
    }

    &-33 {
        width: 33.33%;
    }

    &-34 {
        width: 34%;
    }

    &-50 {
        width: 50%;
    }

    &-66 {
        width: 66.66%;
    }

    &-67 {
        width: 67%;
    }

    &-70 {
        width: 70%;
    }

    &-75 {
        width: 75%;
    }

    &-100 {
        width: 100%;
    }
}
