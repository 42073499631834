.icon-round-50 {
height: 50px;
width: 50px;
padding: 9px 10px;
font-size: 24px;
}

.icon-round-30 {
height: 30px;
width: 30px;
padding: 4px 5px;
font-size: 16px;
}

.icon-round {
border-radius: 100%;
color: #ffffff;
display: inline-block;
text-align:center;
text-transform: uppercase;
}

.icon-lg{
display:block;
font-size:50px;
color:#000000;
}

.fas{
  .fa;
}

.fa-fixed-width {
    width: 16px;
}
