.stats-thead {
    display: flex;
}

.stats-tbody[aria-hidden=false] {
    display: flex;
    flex-wrap: wrap;
}

.stats-tbody[aria-hidden=true] {
    display: none;
}

.stats-tr {
    display: flex;
    width: 100%;

    .stats-td {
        border-bottom: 1px solid rgba(100, 100, 100, 0.10);
    }

    &:hover .stats-td,
    &:hover .stats-th {
        background-color: lightblue;
    }
}

.stats-th,
.stats-td {
    padding: 9px;

    min-width: 10%;
    max-width: 10%;

    &[data-toggle="collapse"] {
        cursor: pointer;
    }
}

.stats-td {
    font-size: 16px;
}

.stats-odd {
    background-color: #f2f2f2;
}

.stats-even {
    background-color: #ffffff;
}

.stats-th-large,
.stats-td-large {
    min-width: 15%;
    max-width: 15%;
}

.stats-th {
    font-size: 20px;
    font-weight: bold;

    text-transform: capitalize;

    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%);
}

a.stats-btn-modal {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
