/* FEUILLE DE STYLE PROPRE A LA GESTION DES FORMULAIRES */

.formulaire {
width: 700px; 
float: left; 
}

.formulaire_gestion {
width: 700px; 
float: left; 
margin: 0px 120px;
border:solid 1px #01A0BE;
}

.column { 
width: 700px; 
float: left; 
padding-bottom: 100px; 
}

.column2 {
background:#F4F4F4;
width: 330px; 
float: left; 
padding: 0px 0px 100px 0px; 
margin: 10px; 
}

.portlet { 
margin: 10px; 
}

.portlet-header{ 
margin: 5px; 
padding-bottom: 4px; 
padding-left: 4px; 
}

.portlet-header .ui-icon { 
float: right; 
}

.portlet-content { 
padding: 5px; 
font-size:11px;
}

.ui-sortable-placeholder { 
border: 1px dotted black; 
visibility: visible !important; 
height: 50px !important; 
}

.ui-sortable-placeholder * { 
visibility: hidden; 
}
