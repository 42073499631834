/*--------------------------- FORMULAIRES ---------------------------*/

input[type="text"]:not(.form-control), input[type="password"]:not(.form-control){
border-radius:2px;
display: inline;
padding: 5px;
border: 1px solid #cccccc;
margin: 0;
color:#222222;
height:34px;
box-shadow:none !important;
font-weight:normal;
}

textarea:not(.form-control){
border-radius:2px;
display: inline;
padding: 3px;
border: 1px solid #cccccc;
margin: 0;
color:#222222;
box-shadow:none !important;
font-weight:normal;
}

input[type="file"]{
border-radius:2px;
display: inline;
background: none repeat scroll 0 0 #FFFFFF;
padding: 3px;
margin: 0;
color:#222222;
box-shadow:none !important;
}

input[type="radio"]{
color:#222222;
}

input[type="checkbox"]{
color:#222222;
}

select{
border-radius:2px;
display: inline;
background: none repeat scroll 0 0 #FFFFFF;
padding: 5px;
border: 1px solid #cccccc;
margin: 0;
color:#222222;
height:30px;
font-weight:normal;
box-shadow:none !important;
font-weight:normal;
}


// label{
// color:#222222;
// font-weight:bold;
// font-size:12px;
// cursor: pointer;
// }

.actions{
padding:10px 0px;
width:100%;
text-align:center;
}

.obligatoire{
  color:#ff0000;
}

.timeline {
  list-style-type: none;
  position: relative;
}
.timeline::before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 9px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
.timeline > .timeline-element {
  margin: 12px 0;
  padding-left: 20px;
}
.timeline > .timeline-element::before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #bed94a;
  left: 0;
  width: 20px;
  height: 20px;
  z-index: 400;
  margin-top: 7px;
}

.input-invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0000' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: #ff0000 !important;

    &:focus {
        border-color: lighten(#ff0000, 20%) !important;
    }

    // On décale davantage vers la gauche pour ne pas cacher le symbole par les petites flèches du champ number.
    &[type=number] {
        background-position: right 36px center;
    }
}
