#top .user_zone li{
  text-align: left;
}

#top .user_zone a{
  cursor: pointer;
}

#top .user_zone .navbar-toggle{
  margin: 0;
}

.dropdown-historique{
  padding:0;
}


#idShowNotificationMessage, #idShowNotification{
  padding:10px;
}

.titre_notifications span {
  padding:5px 0;
  width:100%;
  font-size: 18px;
}

.content_notifications {
  overflow:auto;
  height:270px;
}

.notification-elt {
  border-bottom: 1px dotted #dddddd;
}

.notification-elt a{
  padding:10px;
}

.notification-elt img {
  float:left;
  margin:0 10px 0 0;
}

#notifications .afficher_tout {
  text-align:center;
  font-size:12px;
  padding:3px;
}

#notifications .afficher_tout a {
  text-decoration:none;
  padding:10px;
  color:@color1;
}

#notifications .afficher_tout a:hover {
  color:#ffffff;
}

.notification a,
.notification_read a {
  display:block;
  font-size:12px;
  text-decoration:none;
  color:#444444;
}

.notification-elt .date {
  font-size:11px;
  color:#888888;
}

.notification-elt:hover .date{
  color:#ffffff;
}

.notification {
  background:#e9eaed;
}

.notification_read {
  background:#ffffff;
}

#notifications_status {
  float:right;
  margin:3px 5px 0px 0px;
  height:18px;
}

#notifications_status a {
  text-decoration:none;
  font-size:10px;
  font-weight:normal;
  text-align:left;
  height:18px;
  padding:4px 0px 3px 25px;
}

#notifications_status .read {
  color:#7bb0d5;
}

#notifications_status .unread {

}

#nb_unread_notifications {
  display:block;
  height:16px;
  line-height:16px;
}

.dropdown-messages, .dropdown-alerts {
  width:310px;
}
