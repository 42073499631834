/* Bourse de fret */

.form-bdf .search-choice span{
  margin-right: 12px;
}

.form-bdf .bloc-bdf{
  min-height:250px;
  background:#fdfdfd;
}

.form-bdf h3{
  border-bottom: 1px dotted #999;
  padding-bottom:10px;
}

.form-bdf .bloc-bdf h4{
  border-bottom:2px solid #0876a7;
  color:#0876a7;
  line-height:30px;
  margin-bottom:20px;
}

.form-bdf .bloc-bdf h4 .badge{
  background:#0876a7;
}
