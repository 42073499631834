/*---------------------------BARRE UTILISATEUR---------------------------*/

#barre_utilisateur{
background:#272727 no-repeat center right;
border-radius:0px 0px 0px 10px;
width:100%;
height:31px;
margin:0px;
padding:0% 2%;
font-size:10px;
color:#FFFFFF;
line-height:30px;
}

#barre_utilisateur a{
color:#FFFFFF;
}

#user{
float:right;
padding:0px 0px 0px 0px;
color:#FFFFFF;
}

#menu_outil{
float:right;
padding:2px 0px 0px 15px;
position:relative;
}

#menu_utilisateur{
width:250px;
position:absolute;
right:0;
border:1px solid #C1C0C0;
background:#FFFFFF;
padding:15px;
display:none;
line-height:22px;
z-index:1000;
}

#menu_utilisateur a{
font-size:12px;
color:#0c7e04;
text-decoration:none;
display:block;
width:230px;
padding:3px 10px;
text-align:left;
}

#menu_utilisateur a:hover{
text-decoration:underline;
}

#nom_utilisateur{
font-size:18px;
text-align:left;
color:#ffffff;
}

#menu_utilisateur #mail_utilisateur{
color:#666666;
font-size:12px;
display:block;
text-align:left;
}

#menu_utilisateur #photo_profil{
width:100px;
float:left;
margin-right:10px;
}

#logout{
float:right;
padding:2px 0px 3px 15px;
color:#FFFFFF;
}

#barre_option{
float:right;
color:#ffffff;
}
