// Dropdown Menu Style
.dropdown-menu {
    // box-shadow: rgba(50, 50, 50, 0.5);
    // border-radius: 3px;
    padding: 7px 0px;
    // min-width: 185px;

    > form li,
    > li {

        // First level
        > a {
            font-weight: normal;
            color: black;
            padding: 8px 20px;

            .icon {
                font-size: 16px;
                line-height: normal;
                vertical-align: middle;
                margin-right: 8px;
                color: darken(rgb(253, 253, 253), 59%)
            }

            &:focus,
            &:active {
                background-color: transparent;
                color: black;
            }

            &:hover {
                background-color: darken(rgb(253, 253, 253), 4%);
            }
        }

        // Ajuste le style pour le même résultat sur les boutons.
        > button, > form button {
            border: none;
            display: block;
            clear: both;
            background-color: transparent;
            width: 100%;
            text-align: inherit;

            font-weight: normal;
            color: black;
            padding: 8px 20px;

            .icon {
                font-size: 16px;
                line-height: normal;
                vertical-align: middle;
                margin-right: 8px;
                color: darken(rgb(253, 253, 253), 59%)
            }

            &:focus,
            &:active {
                background-color: transparent;
                color: black;
                outline: none;
                border: none;
            }

            &:hover {
                background-color: darken(rgb(253, 253, 253), 4%);
            }
        }

        .btn-group {
            .btn {
                font-size: 16px;
                color: darken(rgb(253, 253, 253), 59%);
                padding: 0 20px;
            }
        }
    }

    //Divider Line
    .divider {
        margin: 6px 0;
        background-color: rgba(25, 25, 25, 0.5);
    }
}
