/*------------------- PLAN DU SITE -----------------*/
h1.plan_niveau a {
color:#0c7e04;
font-size:20px;
font-weight:normal;
margin:5px 0 10px 0;
padding: 0 0 10px 0;
}

h2.plan_niveau a {
color:#004CA0;
font-size:16px;
font-weight:normal;
margin:2px 0;
padding-left:20px;
border:0px;
}

h3.plan_niveau a{
color:#000000;
font-size:10px;
font-weight:normal;
margin:2px 0;
padding-left:40px;
border:0px;
}

#plan_site ul {
list-style-image:none;
list-style-position:outside;
list-style-type:none;
}
