.loginBody{
  background-color:#e8ebf0;
}

.containerloginForm{
  width:380px;
  margin-top:50px;
  padding:10px 30px 0px 30px;
  background:#ffffff;
  border-radius: 0px 0px 5px 5px;
  border-top:5px solid #01415E;
}

.containerloginForm .logo{
  max-height:150px;
  display:inline-block;
}

.containerloginForm label{
  font-family:'open_sansregular';
  font-size:14px;
  color:#999999;
}

.formLogin{
  margin-top:30px;
}

.formLogin #message_erreur{
  margin:15px 0px;
}

.formLogin input[type="text"], input[type="password"]{
  height:50px;
  font-size:22px;
  font-family:'SourceSansProLight';
  border:none;
  background:#f5f5f5;
}
