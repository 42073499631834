.map{
display:block;
width:100%;
min-height:600px;
}

.panel-body-memo{
  padding:15px 15px 0px 15px;
}

.form-memo{
  border-top:1px solid #dddddd;
  background-color:#e9eff3;
}

.podium{
  min-height:500px;
}


.podium .step{
  font-size:4em;
  text-align:center;
  color:#fff;
}

.podium .step1{
  background:#FFD700;
}

.podium .step2{
  background:#cecece;
}

.podium .step3{
  background:#614E1A;
}

.podium .step-img-round{
  max-width:80px;
  max-height:80px;
  border:1px solid #dddddd;
  margin-bottom:20px;
}

@media(min-width:768px){
  .podium .step-img-round{
    max-width:150px;
    max-height:150px;
  }
}

.podium .step-name{
  font-size:1.4em;
  display:block;
  margin-bottom: 20px;
}

.block-radio{
  border-radius: 10%;
  display:inline-block;
  text-transform: uppercase;
  font-family: "MyriadPro";
}

input[type=radio]:checked + label.block-radio{
  border:5px solid @color-secondary;

  &::after {
    color: hsla(215, 5%, 25%, 1);
    font-family: FontAwesome;
    border: 2px solid @color-secondary;
    content: "\f00c";
    font-size: 24px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
  }
}
