@font-face {
    font-family: 'open_sansregular';
    src: url('@{fonts-path}/opensans-regular.eot');
    src: url('@{fonts-path}/opensans-regular.eot?#iefix') format('embedded-opentype'),
         url('@{fonts-path}/opensans-regular.woff') format('woff'),
         url('@{fonts-path}/opensans-regular.ttf') format('truetype'),
         url('@{fonts-path}/opensans-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'open_sansregular';
    src: url('@{fonts-path}/opensans-regular.ttf') format('truetype');
  }
}

@font-face {
    font-family: 'texgyreadventorbold';
    src: url('@{fonts-path}/texgyreadventor-bold-webfont.eot');
    src: url('@{fonts-path}/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fonts-path}/texgyreadventor-bold-webfont.woff') format('woff'),
         url('@{fonts-path}/texgyreadventor-bold-webfont.ttf') format('truetype'),
         url('@{fonts-path}/texgyreadventor-bold-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'texgyreadventorbold';
    src: url('@{fonts-path}/texgyreadventor-bold-webfont.svg') format('svg');
  }
}

@font-face {
    font-family: 'SourceSansProLight';
    src: url('@{fonts-path}/sourcesanspro-light.eot');
    src: url('@{fonts-path}/sourcesanspro-light.eot') format('embedded-opentype'),
         url('@{fonts-path}/sourcesanspro-light.woff') format('woff'),
         url('@{fonts-path}/sourcesanspro-light.ttf') format('truetype'),
         url('@{fonts-path}/sourcesanspro-light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'SourceSansProLight';
    src: url('@{fonts-path}/sourcesanspro-light.svg') format('svg');
  }
}


@font-face {
    font-family: 'MyriadProLight';
    src: url('@{fonts-path}/myriadpro-light.woff');
    src: url('@{fonts-path}/myriadpro-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('@{fonts-path}/myriadpro-regular.woff');
    src: url('@{fonts-path}/myriadpro-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadProBold';
    src: url('@{fonts-path}/myriadpro-bold.woff');
    src: url('@{fonts-path}/myriadpro-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


