/*---------------------------OPTIONS---------------------------*/

#options .btn{
margin:0 auto !important;
}

#options_titre{
}

#options_titre .h1{
display: inline-block;
margin: 0 10px;
font-weight: normal;
font-family: MyriadPro;
font-size: 20px;
top: 3px;
position: relative;
}
