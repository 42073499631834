/*---------------------- NEWSLETTER ----------------------*/
div.modele_newsletter_imagette{
float:left;
width:200px;
height:200px;
padding:5px;
background:#DDDDDD;
}

div.modele_newsletter_imagette input[type=radio]{
margin-top:10px;
}

#modele_newsletter{
float:left;
width:100%;
font-size:12px;
}

#modele_newsletter .zone_box{
margin:10px;
padding:10px;
background:#DDDDDD;
}

#modele_newsletter .zone_content{
margin:10px;
padding:10px;
}

#modele_newsletter .zone_100{
width:100%;
float:left;
}

#modele_newsletter .zone_50{
width:50%;
float:left;
}
