#slider-code {
width:720px;
height: 200px; 
overflow:hidden; 
}
#slider-code .viewport { 
float: left; 
width: 590px; 
height: 200px; 
overflow: hidden; 
position: relative; 
background:#E7E7E7;
}
#slider-code .buttons { 
display: block;
margin: 30px 10px 0 0; 
float: left; 
width:55px;
text-align:center;
padding-top:30px;
}
#slider-code .next { 
margin: 30px 0 0 10px;  
}
#slider-code .disable { 
visibility: hidden; 
}
#slider-code .overview { 
list-style: none; 
position: absolute; 
padding: 0; 
margin: 0; 
left: 0; 
top: 0; 
}
#slider-code .overview li{ 
float: left; 
margin: 0 5px 0 0; 
padding: 10px; 
height: 200px; 
width:180px;
text-align:center;
}
