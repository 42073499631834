.cursor {
    &-pointer {
        cursor: pointer;
    }
    &-not-allowed {
        cursor: not-allowed;
    }
    &-progress {
        cursor: progress;
    }
    &-wait {
        cursor: wait;
    }
    &-help {
        cursor: help;
    }
    &-text {
        cursor: text;
    }
    &-move {
        cursor: move;
    }
    &-col-resize {
        cursor: col-resize;
    }
    &-row-resize {
        cursor: row-resize;
    }
}
