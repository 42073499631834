.card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}


.card-body {
  padding: 8px 20px 20px;
  border-radius: 0 0 3px 3px;
  flex: 1 1 auto;

  .title{
    font-size:18px;
    color:#333333;
  }

  .value{
    font-size:24px;
  }
}
