.chosen-container {
    width: 100% !important;
    max-width: 100%;

    .chosen-single {
        height: 100% !important;
    }

    .chosen-drop {
        width: 120%;
        max-width: 60vw;
    }
}
