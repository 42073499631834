.activite_societe {
  font-weight: bold;
}

div.activite_ligne {
  font-size: 14px;
  margin-bottom: 10px;
}
div.activite_ligne:hover {
  background: #e2eef8;
}
div.activite_ligne a{
  color:#000000;
}
