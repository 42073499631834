.pao-projet-list-shortcut-buttons-container{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    gap:15px;

    @media (min-width: @screen-md-min) {
      flex-direction: row;
    }
  }

  .pao-projet-list-shortcut-button{
    display:flex;
    margin:0 auto 15px;
  }