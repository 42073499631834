/*---------------------------MOTEUR DE RECHERCHE---------------------------*/

#recherche{
float:right;
margin:20px 0px 0px 0px;
padding:0px 10px 0px 10px;
}

#recherche input{
float:left;
font-size:14px;
background:url('@{img-path}/recherche_input_bg.gif') no-repeat top left;
color:#797878;
border:none;
width:139px;
height:18px;
padding:7px 10px;
margin:0px;
}

#recherche a{
background:url('@{img-path}/recherche_btn.gif') no-repeat top left;
display:block;
float:left;
width:31px;
height:32px;
}

.onglet_recherche{
width:150px;
padding:10px;
margin-right:10px;
}

.onglet_recherche_selected{
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
background:#ffffff;
width:150px;
padding:10px;
margin:5px;
}

.lien_onglet_recherche{
color:#0c7e04;
font-size:12px;
text-decoration:none;
font-weight:bold;
}
