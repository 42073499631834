/*--------------------- MESSAGERIE ------------------*/

.message_lu{
color:#666666;
text-decoration:none;
font-weight:normal;
}

.message_non_lu{
color:#666666;
text-decoration:none;
font-weight:bold;
}

#custom-queue {
border: 1px solid #E5E5E5;
height: 250px;
margin-bottom: 10px;
width: 756px;
}
