/*------------ CATALOGUE --------------*/
h1.titre_catalogue{
color:#FFFFFF;
background:@color-primary;
width:100%;
line-height:40px;
margin-top:0px;
padding:0% 2%;
}

.ligne_familles{
float:left; width:100%;
}
.ligne_produits{
float:left; width:100%;
}
.sous_famille{
float:left; width:100%;
}

.container-lignes-produits div:nth-child(2n+1){
clear:both;
}

@media(min-width:992px){
.container-lignes-produits div:nth-child(2n+1){
clear:none;
}

.container-lignes-produits div:nth-child(4n+1){
clear:both;
}
}

@media(min-width:1200px){
.container-lignes-produits div:nth-child(2n+1){
clear:none;
}

.container-lignes-produits div:nth-child(4n+1){
clear:none;
}
.container-lignes-produits div:nth-child(6n+1){
clear:both;
}
}

.affiche_ligne_produit_catalogue{
background:#E5E5E5;
padding:10px;
margin-top:20px;
min-height:330px;
}

.photo_produit{
text-align: center;
}

a.titre_produit, h2.titre_produit {
color:@color-primary;
text-decoration:none;
font-weight:bold;
float:left;
min-height:80px;
width:100%;
font-size:14px;
line-height:20px;
}
.desc_produit{
text-align:center;
width:100%;
}
a.titre_produit h2{
color:@color-primary;
font-weight:bold;
}

a.lien_famille {
color:@color-primary;
text-decoration:none;
}

.produit {
float:left; width:100%;
padding:2%;
clear:both;
}

.texte_produit{
float:left;
padding:5px 0px 5px 0px;
}

.prix_produit{
color:#FF0000;
font-size:16px;
font-weight:bold;
}

h2.famille_catalogue{
color:@color-primary;
font-weight:normal;
font-size:18px;
margin:0px 0px 5px 0px;
padding:0px;
}

#adresse_facturation{
float:left;
width:320px;
padding:0px;
margin:10px 0px 10px 10px;
}

#adresse_livraison{
float:right;
width:320px;
padding:0px;
margin:10px 10px 10px 0px;
}

#adresse_facturation .contenu, #adresse_livraison .contenu{
float:left;
width:300px;
padding:10px;
margin:0px 0px 0px 0px;
color:#000000;
font-size:12px;
font-weight:normal;
}

#adresse_facturation h1, #adresse_livraison h1{
font-size:14px;
color:#000000;
width:300px;
padding:0px 10px;
margin:0px;
line-height:30px;
font-weight:bold;
}

a.modif_adresse{
color:#000000;
font-size:12px;
font-weight:bold;
text-decoration:none;
}

#listeArticle{
line-height:18px;
font-size:1em;
font-weight:bold;
clear:both;
overflow:hidden;
}
/* SUGGESTION */
.suggestion_produit
{
background-color:#FFFFFF;
padding:10px;
margin:5px;
width:300px;
height:200px;
display:block;
float:left;
}

.zone_suggestion_produit{
float:left;
margin-top:10px;
width:100%;
}
.titre_suggestion_produit{
color:#666;
font-weight:bold;
font-size:16px;
margin:0px 0px 0px 5px;
padding:0px;
}

/* COMMANDE */
#etapes_commande{
text-align:center; background:#01415E; width:100%;
margin:10px 0px 0px 0px;
padding:10px 0px;
}

#etapes_commande .num_etape{
margin-right:5px;
font-weight:bold;
}

#etapes_commande ul{
list-style-type:none;
}

#etapes_commande ul li a{
text-decoration:none;
}

#etapes_commande ul li, #etapes_commande ul li.etape_commande{
display:inline-block;
padding:10px 20px;
font-size:13px;
color:#FFFFFF;
}

#etapes_commande ul li a, #etapes_commande ul li.etape_commande a{
color:#FFFFFF;
}

#etapes_commande ul li.etape_commande_selected{
background:#FFFFFF;
color:#01415E;
}

#etapes_commande ul li.etape_commande_selected a{
color:#01415E;
}

#commande_identification{
width:40%;
float:left;
margin-right:40px;
}

#commande_inscription{
width:50%;
float:left;
border-left:1px solid #DDDDDD;
}

.type_inscription{
color:#355971;
margin-left:10px;
}

#zone_type_inscription{
background:#F6F6F6;
float:left; width:80%;
padding:10px 0px 10px 50px;
}

#inscription_particulier, #inscription_pro{
float:left;
width:50%;
}

#erreur_identification{
color:#FF0000;
font-size:11px;
}

/******************* MISE EN PAGE DES PRODUITS DANS LE CATALOGUE *******************/

.sous_famille{
float:left; width:25%;
}

.sous_famille p{
margin-top:0px;
}

/*---------------------------GESTION COMMANDE---------------------------*/
.info_vert{
color:#00B050;
}

.info_rouge{
color:#DD0000;
}

a.info_lien {
position:relative;
}

a.info_lien span {
display:none;
}

a.info_lien:hover {
background:transparent none repeat scroll 0 0;
cursor:help;
z-index:500;
}

a.info_lien:hover span {
background-color:#FAFAFA;
border:3px solid #CCCCCC;
color:black;
display:inline;
font-size:12px;
font-weight:normal;
left:20px;
padding:5px;
position:absolute;
top:30px;
white-space:nowrap;
}

.etat_global_commmande_vert{
background:#00AA0C;
}

.etat_global_commmande_orange{
background:#EE8016;
}

.etat_global_commmande_rouge{
background:#DD0000;
}
