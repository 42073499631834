.d {
    &-flex {
        display: flex;
    }

    &-block {
        display: block;
    }

    &-inline-block {
        display: inline-block;
    }

    &-none {
        display: none;
    }
}
